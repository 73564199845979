<template>
    <div class="statusquery">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div @click="handFromshow"><el-input
            size="small"
            class="dev_dowm"
            placeholder=""
            suffix-icon="el-icon-arrow-down"
            :readonly="true"
            >
          </el-input></div>
        </div>
        <Tables @handlpages="handlpages" @sortChange="sortChange" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation">
            <div slot="hdfroms" class="status_row" v-if="flag">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item :label="$t('devtable.sbmc')">
                        <el-input v-model="formInline.dev_name" :placeholder="$t('devtable.sbmc')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('devtable.sbxlh')">
                        <el-input v-model="formInline.dev_serial" :placeholder="$t('devtable.sbxlhD')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('devtable.sbzt')">
                        <el-select v-model="formInline.device_state" placeholder="">
                        <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
                        <el-option :label="$t('devtable.lx')" value="0"></el-option>
                        <el-option :label="$t('devtable.zx')" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('devtable.kmms')">
                        <el-select v-model="formInline.lock_mode" placeholder="">
                        <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
                        <el-option :label="$t('devtable.ysms')" value="0"></el-option>
                        <el-option :label="$t('devtable.ckms')" value="1"></el-option>
                        <el-option :label="$t('devtable.cbms')" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </Tables>
    </div>
</template>
<script>
import Tables from '@/components/table'
import methodlist from '../../utils/methods'

export default {
    components:{
        Tables
    },
    data(){
        return{
            flag:false,
           
            tableData: [
            ],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            operation: {
                width:'120px'
            },
            formInline: {
                dev_name: '',
                dev_serial: '',
                device_state: '',
                lock_mode: ''
            },
            UserInfo:'',
            order_by:0
        }
    },
    computed:{
         tableLabel:function(){
             return [
                {
                    prop: 'name',
                    label: this.$t('devtable.azwz'),
                    align: 'center'
                },
                {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbxlh'),
                    align: 'center',
                    sortable:true
                },
                {
                    prop: 'device_state_hint',
                    label: this.$t('devtable.sbzt'),
                    align: 'center'
                },
                {
                    prop: 'unlock_state_hint',
                    label: this.$t('devtable.kmzt'),
                    align: 'center'
                },
                {
                    prop: 'lock_mode_hint',
                    label: this.$t('devtable.kmms'),
                    align: 'center'
                },
                {
                    prop: 'face_number',
                    label: this.$t('devtable.rlsl'),
                    align: 'center'
                },
                {
                    prop: 'card_number',
                    label: this.$t('devtable.mksl'),
                    align: 'center'
                }
            ]
         } ,
        most:function(){
            return {
                ysms:this.$t('devtable.ysms'),
                ckms:this.$t('devtable.ckms'),
                frlj:this.$t('devtable.frlj'),
                rlj:this.$t('devtable.rlj'),
                zx:this.$t('devtable.zx'),
                lx:this.$t('devtable.lx'),
                s:this.$t('devtable.s'),
                f:this.$t('devtable.f'),
                cbms:this.$t('devtable.cbms'),
                j:this.$t('devtable.j'),
                c:this.$t('devtable.c')
            }
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getDevStateList(1);
    },
    methods: {
        // 显示隐藏 查询主体
        handFromshow () {
        this.flag = !this.flag
        },
        //查询
        onSubmit() {
            console.log('查询!');
            this.getDevStateList(1);
        },
        handlpages(page){
            this.getDevStateList(page);
        },
        handleSizeChange(val){
            this.getDevStateList(1,val);
        },
        // 排序
        sortChange(v){
            if(v.order=="descending")
            {
                this.order_by=1
                this.getDevStateList()
            }
            if(v.order=="ascending"){
                this.order_by=0
                this.getDevStateList()
            }
            
        },
        getDevStateList(pageIndex,val){
            const params = {
            method: methodlist.ee_dev_state_list,
            name: this.formInline.dev_name,
            serial_number: this.formInline.dev_serial,
            device_state: (this.formInline.device_state.length == 0 ? '-1':this.formInline.device_state),
            lock_mode: (this.formInline.lock_mode.length == 0 ? '-1' : this.formInline.lock_mode),
            agent_id: this.UserInfo.agent_id,
            manager_id: this.UserInfo.manager_id, 
            page_index: pageIndex?pageIndex:1, 
            page_size: val?val:this.configs.pagesize,
            order_by:this.order_by
            }
            this.$serve(params).then(res => {
                console.log(res);

                this.tableData = res.data.data;
                this.configs.total = res.data.count;
                this.tableData.forEach(el=>{
                    if(el.unlock_state==0){
                        el.unlock_state_hint ={
                            state:true,
                            type:'primary',
                            name:this.most.j
                        };
                    }else{
                        el.unlock_state_hint ={
                            state:true,
                            type:'primary',
                            name:this.most.c
                        };
                    }
                    if(el.lock_mode == 0)
                    el.lock_mode_hint = {state:true, name:this.most.ysms, };
                    else if(el.lock_mode == 1)
                    el.lock_mode_hint = {state:true, name: this.most.ckms };
                    else
                    el.lock_mode_hint = {state:true, name: this.most.ckms};

                    if(el.device_state == 0)
                    el.device_state_hint = {state:true, type: 'warning', name: this.most.lx};
                    else
                    el.device_state_hint = {state:true, type: 'success', name: this.most.zx};
                })
            });
        },
    }
}
</script>
<style lang="less" scoped>
.statusquery{
    background-color: #fff;
    padding: 20px;
    
}
.status_row{
    display: flex;
}
.dev_dowm{
    margin-left: 30px;
}
</style>